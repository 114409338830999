import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VRButton } from 'three/examples/jsm/webxr/VRButton';

const canvas = document.getElementById('canvas');
const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(80, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.set(0, 0, 0);
scene.add(camera);

// const grid = new THREE.GridHelper(500, 20, 0xffffff);
// grid.material.opacity = 0.2;
// grid.material.transparent = true;
// scene.add(grid);

const geometry = new THREE.SphereGeometry(0.01, 8, 8);
const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
const size = 9;
const sphere = new THREE.InstancedMesh(geometry, material, size ** 3);
const dummy = new THREE.Object3D();
scene.add(sphere);

const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.shadowMap.enabled = true;
renderer.xr.enabled = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.physicallyCorrectLights = true;
renderer.setAnimationLoop(() => {
    const position = camera.position.clone().floor();
    for (let i = 0, x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            for (let z = 0; z < size; z++, i++) {
                dummy.position.set(x - size / 2, y - size / 2, z - size / 2);
                dummy.position.add(position);
                dummy.updateMatrix();
                sphere.setMatrixAt(i, dummy.matrix);
            }
        }
    }
    renderer.render(scene, camera);
});

const controls = new OrbitControls(camera, renderer.domElement);
controls.target.set(0, 1, 1);
controls.update();

window.addEventListener('resize', resize, false);
document.body.appendChild(VRButton.createButton(renderer));

function resize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}
